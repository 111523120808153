
const isProduction = process.env.NODE_ENV === "production";
const current_host = window.location.host;

export const config = {
	build: 1,
	isProduction: isProduction,
	isTest: !isProduction,
	host: current_host,
	urls: {
		api: isProduction 
			? process.env.API_HOST || "https://cw-dev-api.cloudwaitress-test.xyz" 
			: "http://localhost:3010",
		// api: isProduction ? "https://api.cloudwaitress.com" : "https://api-dev.ap.ngrok.io",
		api2: isProduction 
			? process.env.API2_HOST || "https://cw-dev-api.cloudwaitress-test.xyz" 
			: "http://localhost:3010",
		s3: process.env.S3_BUCKET || "https://s3.amazonaws.com/ordering-platform",
		cloudfront: "https://dol98aud6tbh0.cloudfront.net",
	},
	services: {
		amplitude: {
			api_key: isProduction ? "dd8d0f25461f3da1628fa5ec95aa5bc5" : "b2fa8807128788a1a0b4e9b474883dc8",
		},
		api: {
			public_key: "c2285f76-cc39-4ade-9269-023192e1798b",
		},
		google: {
			api_key: "AIzaSyAc_6JJnBdHbKjXGeoKIznTE22-YaRRuSA",
			recaptcha_enterprise: {
				enabled: process.env.RECAPTCHA_ENTERPRISE_ENABLED || "true",
				site_key: process.env.RECAPTCHA_ENTERPRISE_SITE_KEY || "6LftLlchAAAAADuqwP9LNFzKN7Bfrh6WVtB9B-B6",
			}
		},
		mapbox: {
			api_key: "pk.eyJ1IjoicmVhbGZyZXNoIiwiYSI6ImNqb2hkeTdzbTBvczMzcXBqeGE0MXlwbjkifQ.L_3vQEe6bef12JJyt6268w",
		},
		stripe: {
			public_key: isProduction 
				? process.env.STRIPE_PUBLIC_KEY || "pk_live_badbK5ZN12WDy7Rp0ADCXUa0" 
				: "pk_test_qEboEw24dxDDWiAyDFUy886E",
		},
	},
};
