import React from 'react';
import { FastField, FastFieldProps, Field, FormikProps } from 'formik';
import { MobXProviderContext } from 'mobx-react';

import {
  Button,
  DateTimeRanges,
  FieldOpeningHours,
  FormGroup,
  Input,
  InputGroup,
  SelectAdv,
  Switch,
} from '@lib/components';
import { FreeItemPromo, ConventionalDiscountPromo } from '../promos/type';
import { getPaymentMethods } from '../promos/util';
import { ListOrderTimesOptions, ListServicesOptions } from '@lib/common';

interface Props {
  form: FormikProps<FreeItemPromo | ConventionalDiscountPromo>;
  getFieldError: (
    form: FormikProps<FreeItemPromo | ConventionalDiscountPromo>,
    field: string
  ) => T.ObjectAny | string | undefined | null;
}

export const LimitsTab = ({ form, getFieldError }: Props) => {
  const { store } = React.useContext(MobXProviderContext);
  const restaurant = store.restaurant;
  const paymentMethods = getPaymentMethods(restaurant);
  const discountType = form.values.condition.type;
  const currency = store.intl.s.currency;

  return (
    <div className="p-4">
      <FastField name="restrictions.service_type">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Services"
            help="Select which services this promo will be valid for.Leave empty for it to be valid for all services"
            error={getFieldError(form, 'restrictions.service_type')}
          >
            <SelectAdv
              type="multi"
              value={field.value}
              onChange={(options: string[]) => {
                form.setFieldValue('restrictions.service_type', options);
              }}
              options={ListServicesOptions}
            />
          </FormGroup>
        )}
      </FastField>

      <FastField name="restrictions.payment_methods">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Payment Methods"
            help="Select which payment methods this promo will be valid for.Leave empty for it to be valid for all payment methods"
            error={getFieldError(form, 'restrictions.payment_methods')}
          >
            <SelectAdv
              type="multi"
              value={field.value}
              onChange={(options: string[]) =>
                form.setFieldValue('restrictions.payment_methods', options)
              }
              options={paymentMethods}
            />
          </FormGroup>
        )}
      </FastField>

      <FastField name="restrictions.order_times">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Order Times"
            help="Select which order times this promo will be valid for,I.e oreders due immediately or pre-orders. Leave empty for it to be valid at all times."
            error={getFieldError(form, 'restrictions.order_times')}
          >
            <SelectAdv
              type="multi"
              value={field.value || []}
              onChange={(v: string[]) =>
                form.setFieldValue('restrictions.order_times', v)
              }
              options={ListOrderTimesOptions}
            />
          </FormGroup>
        )}
      </FastField>

      <Field name="restrictions.min_order">
        {({ field }: FastFieldProps) => (
          <FormGroup
            optional={true}
            title="Minimum Order"
            help="The minimum value of the customers cart before this promotion can apply, doesn't include other fees"
            error={getFieldError(form, 'restrictions.min_order')}
          >
            <InputGroup
              iconHtml={<p className="font-semi-bold">{currency.symbol}</p>}>
              <Input type="number" step={currency.step} min={0} {...field} />
            </InputGroup>
          </FormGroup>
        )}
      </Field>

      <Field name="restrictions.max_uses">
        {({ field }: FastFieldProps) => (
          <FormGroup
            optional={true}
            title="Maximum Number Of Uses"
            help="The maximum number of times this promotion can be used totally, leave empty for unlimited"
            error={getFieldError(form, 'restrictions.max_uses')}
          >
            <Input type="number" step={'1'} min={1} {...field} />
          </FormGroup>
        )}
      </Field>

      <FastField name="restrictions.once_per_customer">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Once Per Customer"
            help="Allows a customer to only redeem this promotion only once. If a customer is not logged in, it resets if their browser cookies are cleared"
            error={getFieldError(form, 'restrictions.once_per_customer')}
          >
            <Switch
              id="restrictions.once_per_customer"
              checked={field.value}
              onChange={e =>
                form.setFieldValue(
                  'restrictions.once_per_customer',
                  e.target.checked
                )
              }
            />
          </FormGroup>
        )}
      </FastField>

      <FastField name="restrictions.authenticated_user_only">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Logged In Customers Only"
            help="This promo can only be redeemed by customers who have created an account and logged in"
            error={getFieldError(form, 'restrictions.authenticated_user_only')}
          >
            <Switch
              id="restrictions.authenticated_user_only"
              checked={field.value}
              onChange={e =>
                form.setFieldValue(
                  'restrictions.authenticated_user_only',
                  e.target.checked
                )
              }
            />
          </FormGroup>
        )}
      </FastField>

      <FastField name="restrictions.available_date_range">
        {({ field }: FastFieldProps) => (
          <FormGroup
            optional={true}
            title="Available Dates"
            help="Set specific date ranges when this promotion can be used. Ignore this if the promotion is valid on any date."
            error={getFieldError(form, 'restrictions.available_date_range')}
          >
            <Button
              type="button"
              color="primary-inverse"
              size="xs"
              onClick={() => {
                form.setFieldValue('restrictions.available_date_range', [
                  ...field.value,
                  { start: '', end: '' },
                ] as T.Core.Business.BusinessDateTimeRanges);
              }}
            >
              Add Date Range
            </Button>
            <DateTimeRanges
              disableTimePicker
              formats={restaurant.settings.region.formats}
              locale={restaurant.settings.region.locale}
              timezone={restaurant.settings.region.timezone}
              values={field.value}
              allowNone={true}
              onChange={values =>
                form.setFieldValue('restrictions.available_date_range', values)
              }
            />
          </FormGroup>
        )}
      </FastField>

      <FastField name="restrictions.available_times">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Available Times"
            help="Enter time in 24H format, eg 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open"
            error={getFieldError(form, 'restrictions.available_times')}
          >
            <div className="m-tb-3 block">
              <FieldOpeningHours
                allowNone={true}
                hours={field.value}
                onChange={opening_hours =>
                  form.setFieldValue(
                    'restrictions.available_times',
                    opening_hours
                  )
                }
              />
            </div>
          </FormGroup>
        )}
      </FastField>

      <FastField name="restrictions.automatically_apply">
        {({ field }: FastFieldProps) => (
          <FormGroup
            optional={true}
            title="Automatically Apply Promotion"
            help="Automatically apply the promotion to a customers order if the above requirements are met. The promo may be removed from the cart manually."
            error={getFieldError(form, 'restrictions.automatically_apply')}
          >
            <Switch
              id="restrictions.automatically_apply"
              checked={field.value}
              onChange={e =>
                form.setFieldValue(
                  'restrictions.automatically_apply',
                  e.target.checked
                )
              }
            />
          </FormGroup>
        )}
      </FastField>
    </div>
  );
};
