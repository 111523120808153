import { FormikActions, FormikProps } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { UI } from './ui';
import { logger, RestaurantUtils } from '@lib/common';
import { RootStore } from '../mobx/store';
import { has, get, isPlainObject } from 'lodash';

type DefaultRange = {
  up_to: number;
  min_order: number;
  cost?: number;
};

type FixedFee = {
  up_to: number;
  cost: number;
};

type Offset = {
  up_to: number;
  offset: number;
};

export interface FormFieldValidators<T> {
  [key: string]: (values: T) => void | {
    [key: string]: string;
  };
}

interface SubmitRestaurantArgs<T> {
  store: RootStore;
  r: T.Schema.Restaurant.RestaurantSchema;
  form: FormikActions<T>;
  setError: (err: string | null) => void;
  setRestaurant: (r: T.Schema.Restaurant.RestaurantSchema) => void;
  process: (r: T.Schema.Restaurant.RestaurantSchema) => Promise<{
    r: T.Schema.Restaurant.RestaurantSchema;
    update: T.ObjectAny;
  }>;
  onSuccess?: (r: T.Schema.Restaurant.RestaurantSchema) => void;
  onError?: () => void;
  onFail?: () => void;
  onSuccessMessage?: string;
  onErrorMessage?: string;
  deliverySubsidySetting?: any;
  isFromStripeSetting?: boolean;
}

interface SubmitOrganisationArgs<T> {
  store: RootStore;
  o: T.Schema.Organisation.OrganisationSchema;
  form: FormikActions<T>;
  setError: (err: string | null) => void;
  setOrganisation: (o: T.Schema.Organisation.OrganisationSchema) => void;
  process: (o: T.Schema.Organisation.OrganisationSchema) => Promise<{
    o: T.Schema.Organisation.OrganisationSchema;
    update: T.ObjectAny;
  }>;
  onSuccess?: (o: T.Schema.Organisation.OrganisationSchema) => void;
  onError?: () => void;
  onFail?: () => void;
  onSuccessMessage?: string;
  onErrorMessage?: string;
}

interface SubmitWebsiteArgs<T> {
  store: RootStore;
  w: T.Schema.Website.WebsiteSchema;
  form: FormikActions<T>;
  setError: (err: string | null) => void;
  setWebsite: (w: T.Schema.Website.WebsiteSchema) => void;
  process: (w: T.Schema.Website.WebsiteSchema) => Promise<{
    w: T.Schema.Website.WebsiteSchema;
    update: T.ObjectAny;
  }>;
  onSuccess?: (w: T.Schema.Website.WebsiteSchema) => void;
  onError?: () => void;
  onFail?: () => void;
  onSuccessMessage?: string;
  onErrorMessage?: string;
}

export const FormHelpers = {
  async submit_restaurant<T>(args: SubmitRestaurantArgs<T>) {
    const {
      store,
      form,
      setError,
      setRestaurant,
      process,
      onSuccess,
      onError,
      onFail,
      onSuccessMessage,
      onErrorMessage,
      deliverySubsidySetting,
      isFromStripeSetting
    } = args;
    try {
      const r = cloneDeep(args.r);
      setError(null);

      const processResult = await process(r);
      const promise = [
        store.api.restaurant_update({
          _id: r._id,
          update: processResult.update,
        })
      ]
      if (deliverySubsidySetting) {
        const defaultProvider = RestaurantUtils.settings.getDefaultDeliveryProvider(r);
        const deliverySettings = r.settings.services.delivery;
        const subsidyType = defaultProvider === 'restaurant'
          ? deliverySettings.fee.subsidy?.subsidy_type
          // @ts-ignore
          : deliverySettings.providers[defaultProvider].delivery_fee_settings.subsidy_type;
        const updateData: {
          default_ranges: DefaultRange[];
          fixed_fee?: FixedFee[];
          offset?: Offset[];
        } = {
          default_ranges:[],
          ...subsidyType === 'fixed_fee' && {fixed_fee: []},
          ...subsidyType !== 'fixed_fee' && {offset: []},
        }
        deliverySubsidySetting.forEach((item: any) => {
          updateData.default_ranges.push({
            up_to: item.up_to,
            min_order: item.min_order,
            ...defaultProvider !== 'uber' && { cost: item.range.cost },
          });
          subsidyType === 'fixed_fee'
            ? updateData.fixed_fee?.push({up_to: item.up_to, cost: item.cost})
            : updateData.offset?.push({up_to: item.up_to, offset: item.range.offset});
        })

        const requestData = {
          defaultProvider: defaultProvider,
          settings: {
            subsidy_type: subsidyType,
            update: updateData
          }
        }
        promise.push(store.api.restaurant_update_delivery_subsidy({
          _id: r._id,
          requestData
        }))
      }
      const [apiResult, deliverySubsidyResult] = await Promise.all(promise);
      if (isFromStripeSetting) {
        const verifyResult = await store.api.dashboard_restaurant_domain_verify({restaurant_id: r._id});
        if (verifyResult.outcome) {
          setError(verifyResult.message);
          if (onFail) onFail();
        }
      }

      if (apiResult.outcome) {
        setError(apiResult.message);
        if (onFail) {
          onFail();
        }
      } else if (deliverySubsidyResult && deliverySubsidyResult.outcome) {
        setError(deliverySubsidyResult.message);
        if (onFail) {
          onFail();
        }
      } else {
        UI.notification.success(onSuccessMessage || 'Settings updated');
        setRestaurant(r);
        if (onSuccess) {
          onSuccess(r);
        }
      }
    } catch (e) {
      logger.captureException(e);
      setError(onErrorMessage || 'Error updating settings, please try again or contact us');
      if (onError) {
        onError();
      }
    } finally {
      form.setSubmitting(false);
    }
  },

  async submit_website<T>(args: SubmitWebsiteArgs<T>) {
    const { store, form, setError, setWebsite, process, onSuccess, onError, onFail, onSuccessMessage, onErrorMessage } =
      args;
    try {
      const w = cloneDeep(args.w);
      setError(null);

      const processResult = await process(w);

      const apiResult = await store.api.website_update({
        _id: w._id,
        update: processResult.update,
      });

      if (apiResult.outcome) {
        setError(apiResult.message);
        if (onFail) {
          onFail();
        }
      } else {
        UI.notification.success(onSuccessMessage || 'Settings updated');
        setWebsite(w);
        if (onSuccess) {
          onSuccess(w);
        }
      }
    } catch (e) {
      logger.captureException(e);
      setError(onErrorMessage || 'Error updating settings, please try again or contact us');
      if (onError) {
        onError();
      }
    } finally {
      form.setSubmitting(false);
    }
  },

  async submit_organisation<T>(args: SubmitOrganisationArgs<T>) {
    const { store, form, setError, setOrganisation, process, onSuccess, onError, onFail, onSuccessMessage, onErrorMessage } =
      args;
    try {
      const o = cloneDeep(args.o);
      setError(null);

      const processResult = await process(o);

      const apiResult = await store.api.organisation_update({
        _id: o._id,
        update: processResult.update,
      });

      if (apiResult.outcome) {
        setError(apiResult.message);
        if (onFail) {
          onFail();
        }
      } else {
        UI.notification.success(onSuccessMessage || 'Info updated');
        setOrganisation(o);
        if (onSuccess) {
          onSuccess(o);
        }
      }
    } catch (e) {
      logger.captureException(e);
      setError(onErrorMessage || 'Error updating Info, please try again or contact us');
      if (onError) {
        onError();
      }
    } finally {
      form.setSubmitting(false);
    }
  },

  validate<T>(values: T, validators: FormFieldValidators<T>) {
    let errors: { [key in keyof T]?: string } = {};

    for (const validator in validators) {
      if (validators.hasOwnProperty(validator)) {
        const validate = validators[validator];
        if (validate) {
          errors = Object.assign(errors, validate(values));
        }
      }
    }

    let isError = false;

    for (const key in errors) {
      if (errors.hasOwnProperty(key) && errors[key as keyof typeof errors]) {
        isError = true;
        break;
      }
    }

    return { errors, isError };
  },

  error<T>(form: FormikProps<T>, field: string): string | undefined | null {
    const { submitCount, errors } = form;
    // Support nested form fields using dot notation.
    if (submitCount > 0 && has(errors, field)) {
      const errorData = get(errors, field);
      if (Array.isArray(errorData) && errorData.length > 0) {
        if (isPlainObject(errorData[0])) {
          return get(Object.values(errorData[0]), '0');
        }
      }
      return errorData;
    }
    return null;
  },
};
